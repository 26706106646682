import React from "react";
import clsx from "clsx";
import { useSwiper } from "swiper/react";
import IconArrow from "../UI/Icon/IconArrow/IconArrow";
import styles from "./SwiperNavigationButton.module.scss";
import { AdditionalArgs, CommonArgs } from "@/lib/searchspring/tracker";
import {
	genericSendBeaconEvent,
	beaconTrackProfileClick,
} from "@/lib/searchspring/tracker";
import { StoreRegion } from "@/lib/builder/builder.config";

interface SwiperNavigationButtonsProps {
	swiperRef?: any;
	buttonIdentifier: string;
	buttonYPosition?: "middle";
	clickData?: CommonArgs & AdditionalArgs;
	store?: StoreRegion;
}

interface SwiperNavigationButtonProps extends SwiperNavigationButtonsProps {
	movement: "previous" | "next";
	clickData?: CommonArgs & AdditionalArgs;
	store?: StoreRegion;
}

const SwiperNavigationButton: React.FC<SwiperNavigationButtonProps> = ({
	movement,
	swiperRef = {},
	buttonIdentifier,
	buttonYPosition,
	clickData,
	store,
}) => {
	const swiper = useSwiper();
	// If rendered outside of the swiper element use the onSwiper propr to pass the swiper instance through
	const ref = swiperRef?.enabled ? swiperRef : swiper;

	return (
		<button
			type='button'
			aria-label={`button-${movement}`}
			data-button-ref={`${buttonIdentifier}-button-${movement}`}
			className={clsx([
				styles.carouselArrowButtons,
				styles[`buttonPlacement--${movement}`],
				[buttonYPosition === "middle" ? styles.buttonYPosition : ""],
			])}
			onClick={() => {
				movement === "next" ? ref.slideNext() : ref.slidePrev();

				if (clickData && store) {
					const event = [beaconTrackProfileClick(clickData)];
					genericSendBeaconEvent(event, store);
				}
			}}
		>
			<IconArrow
				direction={movement}
				aria-labelledby={`directional chevron pointing down ${movement}`}
			/>
		</button>
	);
};

const SwiperNavigationButtons: React.FC<SwiperNavigationButtonsProps> = ({
	swiperRef,
	buttonIdentifier,
	clickData,
	store,
}) => {
	return (
		<>
			<SwiperNavigationButton
				movement='previous'
				swiperRef={swiperRef}
				buttonIdentifier={buttonIdentifier}
				buttonYPosition='middle'
				clickData={clickData}
				store={store}
			/>

			<SwiperNavigationButton
				movement='next'
				swiperRef={swiperRef}
				buttonIdentifier={buttonIdentifier}
				buttonYPosition='middle'
				clickData={clickData}
				store={store}
			/>
		</>
	);
};

export default SwiperNavigationButtons;
